/**
 * Created by 胡子哥 on 2021/09/12.
 */
import {$http} from '@/common/axios';
import {_CONFIG} from '@/config/config';
class ServicesGlobal {
    constructor() {
        this.path = {
            api: _CONFIG.apiPath+"/admin",
            www: _CONFIG.apiPath+"/www",
            apiPublic:_CONFIG.apiPath+"/www/public",
        }
    }

    postParams(obj) {
        let params = new URLSearchParams();
        for (let key in obj) {
            params.append(key, obj[key]);
        }
        return params;
    }

    getMilitias(data={}){
        return $http.get(`/code/get`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    //获取验证码
    getImgCode(data){
        return $http.get(`${this.path.apiPublic}/imgCaptcha`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    upFile(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/articleClass/upFile`, params)
            .then(function (response) {
                return response.data;
            });
    }

    select_coordinate_arms(data) {
        let params = this.postParams(data);
        return $http.post(`http://10.10.18.18:7075/mboss/api.php?url=map/select_coordinate_arms`, params)
            .then(function (response) {
                return response;
            });
    }

    getQrCode(url){
        return $http.get(url)
            .then(function (response) {
                return response.data;
            });
    }

    getSystemInfo(data = {}){
        return $http.get(`${this.path.www}/system/show`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    getTableFields(data={}){
        return $http.get(`${this.path.api}/tools/getTableFields`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    // router /boss/team/exportExcel
    exportExcel(routerName,data) {
        let exportMap = {
            "user":"/user/exportExcel",
            "goods_order":"/order/exportExcel",
            "goods_order_po":"/orderPo/exportExcel",
            "goods_order_po_pay":"/orderPoPay/exportExcel"
        }
        let router = exportMap[routerName]
        return $http.get(`${this.path.api}${router}`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    logistics(data={}){
        return $http.get(`${this.path.api}/logistics`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

}

export {
    ServicesGlobal
};