import {createRouter, createWebHashHistory} from 'vue-router'
import {openRoutes} from './open'
import {adminRoutesIndex, adminRoutesOrder, adminRoutesEmployee, adminRoutesCard,adminRoutesGiftBag,extractOrderRoutes,withdrawalRoutes,systemMessageRoutes,adminRoutesTool} from './admin'

const constantRoutes = [
    ...openRoutes,
    ...adminRoutesIndex,
    ...adminRoutesOrder,
    ...adminRoutesEmployee,
    ...adminRoutesCard,
    ...adminRoutesGiftBag,
    ...extractOrderRoutes,
    ...withdrawalRoutes,
    ...systemMessageRoutes,
    ...adminRoutesTool,
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
});

export {
    adminRoutesIndex,
    adminRoutesOrder,
    adminRoutesEmployee,
    adminRoutesCard,
    adminRoutesGiftBag,
    extractOrderRoutes,
    withdrawalRoutes,
    systemMessageRoutes,
    adminRoutesTool,
    constantRoutes,
    router
}