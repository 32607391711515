import {$http} from '@/common/axios';
import {ServicesGlobal} from './services.js';

class OrderPoServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    create(data = {}) {
        let params = this.postParams(data);
        return $http.post(`{admin}/orderPo/create`, params)
            .then(function (response) {
                return response.data;
            });
    }
    createPackage(data = {}) {
        let params = this.postParams(data);
        return $http.post(`{admin}/orderPo/createPackage`, params)
            .then(function (response) {
                return response.data;
            });
    }


    delete(ids,data = {}) {
        let params = this.postParams(data);
        return $http.delete(`{admin}/orderPo/delete/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    cancelOrder(ids,data = {}) {
        let params = this.postParams(data);
        return $http.delete(`{admin}/orderPo/cancel/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    update(id,data = {}) {
        let params = this.postParams(data);
        return $http.put(`{admin}/orderPo/update/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    updateField(id,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/orderPo/updateField/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    changeStatus(ids,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/orderPo/changeStatus/${ids}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    show(data = {}) {
        return $http.get(`{admin}/orderPo/show`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }
    list(data = {}) {
        return $http.get(`{admin}/orderPo/list`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }
    pay(data = {}) {

        let params = this.postParams(data);
        return $http.post(`{admin}/orderPo/pay`, params)
            .then(function (response) {
                return response.data;
            });
    }

    details(id,data = {}) {
        return $http.get(`{admin}/orderPo/details/${id}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }
    shipments(id,data={}){
        let params = this.postParams(data);
        return $http.put(`{admin}/orderPo/shipments/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }
}


export default (new OrderPoServices())