<template>
  <router-view/>
</template>

<script>
import {util} from "@/common/util";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      breadCrumbs: [],
    }
  },
  created() {

  },
  methods: {
    browser() {
      console.log(util._browser())
    }
  }
}
</script>
<style>
</style>
