<template>
  <router-link to="/" class="site-name" :class="'site-name-'+size">
    <img :src="CONFIG.host+'/static/images/logo.svg'">
    <span class="slogan">{{ CONFIG.slogan }}</span>
  </router-link>
</template>

<script>
import {_CONFIG} from '@/config/config'

export default {
  name: "logoCom",
  props: {
    slogan: {
      type: String,
      default:"一个有温度的福礼社",
    },
    size:{
      type: String,
      default:"XL",
    },
  },
  data() {
    return {
      CONFIG: _CONFIG
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../css/_var.scss";
.site-name {
  font-size: 28px;
  line-height: 28px;
  color: $primaryColor;
  font-weight: 400;

  position: relative;

  width: 68px;
  height: 66px;
  display: block;
  img {
    width: 68px;
  }

  .slogan {
    font-size: 13px;
    color: $primaryColor;
    white-space: nowrap;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-top: -26px;
    margin-left: -16px;
  }
}

.site-name-XL{
  width: 68px;
  height: 68px;
  img {
    width: 68px;
  }
  .slogan {
    margin-top: -26px;
    margin-left: -16px;
  }
}

</style>