<template>
  <div class="site-header">
    <div class="inbox">
      <div class="start">
        <logoCom size="XL"></logoCom>
        <div class="open-page-title">{{pageTitle}}</div>
      </div>
      <div class="end">
        <router-link to="/login" class="col login-register"
                     v-if="routePath==='/register'"><i class="icon-head"></i> 登录</router-link>
        <router-link to="/register" class="col login-register"
                     v-if="routePath==='/login'"><i class="icon-head"></i> 注册</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {_data, _methods} from '@/common/public'
import logoCom from "@/components/logo/logo.vue";

export default {
  components: {
    logoCom,
  },
  props: {
    pageTitleDefault: {
      type: String,
      default: '',
    },
  },
  computed: {
    randomId () {
      return this.$route.fullPath + new Date()
    }
  },
  data() {
    return {
      ..._data,
      systemConfig: {
        title: "智业福利网",
        cartCount:0,
      },
      classNav:[],
      navHighlight:false,
      routePath:"",
      pageTitle:"",
    }
  },
  created() {
    this.routePath = this.$route.path  // /register
    this.pageTitle = this.pageTitleDefault
  },
  mounted() {

  },
  watch: {
    "$route": function () {
      this.pageTitle = this.$route.meta.title
      this.routePath = this.$route.path
    },
  },
  methods: {
    ..._methods,
  },
}
</script>

<style lang="scss" scoped>
@import '../../css/_var.scss';
$siteHeaderHeight: 90px;
.site-header {
  background: #fff;
  height: $siteHeaderHeight;
  .inbox {
    display: flex;
    position: relative;
  }

  .site-name{
    position: relative;
    left: 0;
    top: 0;
  }

  .start {
    height: $siteHeaderHeight;
    display: flex;
    align-items: center;
  }

  .end {
    flex: 1;
    text-align: right;
    width: 300px;
    height: $siteHeaderHeight;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;

    .col {
      margin-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    .login-register {
      padding: 0 10px;
    }

    [class^='icon-'] {
      font-size: 22px;
      margin-right: 6px;
      color: $primaryColor;
    }
  }

}

.open-page-title{
  font-size: 22px;
  margin-left: 120px;
}
</style>
