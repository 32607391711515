<template>
  <div class="site-header">
    <div class="start"><span class="site-name">{{ systemConfig.title }}</span></div>
    <div class="end">
      <span class="user-name">HI，{{ userInfo.realname || userInfo.nickname || userInfo.username }}</span>
      <span class="switch-user" @click="logout">退出用户</span>
    </div>
  </div>
</template>

<script>
import {_data, _methods, util} from '@/common/public'

export default {
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ..._data,
      systemConfig: {
        title: "河南智业福利网"
      },
      userInfo: {realname: '', nickname: '', username: ''},
    }
  },
  created() {
    this.userInfo = util.getLocalStorage('userInfo', {});
    this.systemConfig = util.getLocalStorage('systemConfig',{})
  },
  methods: {
    ..._methods,
    logout() {
      localStorage.setItem("TOKEN", "")
      localStorage.setItem("userInfo", "")
      localStorage.setItem('userConfig', "");
      localStorage.setItem('AuditStatus',"");
      this.$router.push({path:"/index"});
    },
  },
}
</script>

<style lang="scss" scoped>
.site-header {
  display: flex;
  background: #fff;
  height: 70px;
  margin-bottom: 16px;
}

.site-header-placeholder {
  height: 70px;
  margin-bottom: 16px;
}

.site-header{
  .start {
    width: 50%;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .site-name {
    font-size: 20px;
    cursor: pointer;
  }

  .end {
    flex: 1;
    text-align: right;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;

    span {
      margin-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.site-header .switch-user {
  cursor: pointer;
}

.site-header .switch-user:hover {
  /*background: #04d065;*/
}
</style>
