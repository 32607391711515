//import open from "@/layout/Open";
import platform from "@/layout/Platform";

const adminRoutesIndex = [
    {
        path: '/admin',
        component: platform,
        redirect: '/vpoOrder/index',
        name: "admin",
        meta: {title: '管理首页', classify: 'admin', icon: 'admin', group:'admin',auditStatus:1,isOpen: false, isShow: 0},
        children: [
            {
                path: 'index',
                redirect: '/vpoOrder/index',
                component: () => import('@/views/admin/index'),
                name: 'adminIndex',
                meta: {title: '管理首页', classify: 'admin', icon: 'admin', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
]

const adminRoutesOrder = [
    {
        path: '/vpoOrder',
        component: platform,
        redirect: '/vpoOrder/index',
        name: "vpoOrder'",
        meta: {title: '采购订单', classify: 'vpoOrder', icon: 'vpoOrder', group:'admin', table:'goods_order_po',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/vpoOrder/index'),
                name: 'vpoOrderIndex',
                meta: {title: '采购订单', classify: 'vpoOrder', icon: 'vpoOrder', group:'admin', table:'goods_order_po',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/vpoOrder/detail.vue'),
                name: 'vpoOrderDetail',
                meta: {title: '采购订单详情', classify: 'vpoOrder', icon: 'vpoOrder', group:'admin', table:'goods_order_po',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'refund/:order_id',
                component: () => import('@/views/vpoOrder/refund.vue'),
                name: 'refundOrderDetail',
                meta: {title: '选择退货商品', classify: 'vpoOrder', icon: 'vpoOrder', group:'admin', table:'goods_order_po',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'logistics/:id',
                component: () => import('@/views/vpoOrder/logistics.vue'),
                name: 'logistics',
                meta: {title: '物流信息', classify: 'vpoOrder', icon: 'vpoOrder', group:'admin', table:'goods_order_po',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'logisticsDetail/:sn',
                component: () => import('@/views/vpoOrder/logisticsDetail.vue'),
                name: 'logisticsDetail',
                meta: {title: '物流信息', classify: 'vpoOrder', icon: 'vpoOrder', group:'admin', table:'goods_order_po',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/businessOrder',
        component: platform,
        redirect: '/businessOrder/index',
        name: "businessOrder'",
        meta: {title: '自选订单记录', classify: 'businessOrder', icon: 'businessOrder', group:'admin', table:'goods_order',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/businessOrder/index'),
                name: 'businessOrderIndex',
                meta: {title: '自选订单', classify: 'businessOrder', icon: 'businessOrder', group:'admin', table:'goods_order',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/businessOrder/detail.vue'),
                name: 'businessOrderDetail',
                meta: {title: '自选订单详情', classify: 'businessOrder', icon: 'businessOrder', group:'admin', table:'goods_order',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/refundOrder',
        component: platform,
        redirect: '/refundOrder/index',
        name: "refundOrder'",
        meta: {title: '采购退货订单', classify: 'refundOrder', icon: 'refundOrder', group:'admin', table:'goods_order',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/refundOrder/index'),
                name: 'refundOrderIndex',
                meta: {title: '采购退货订单', classify: 'refundOrder', icon: 'refundOrder', group:'admin', table:'goods_order',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/refundOrder/detail.vue'),
                name: 'brefundOrderDetail',
                meta: {title: '采购退货订单详情', classify: 'refundOrder', icon: 'refundOrder', group:'admin', table:'goods_order',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
]

const adminRoutesEmployee = [
    {
        path: '/employeeManage',
        component: platform,
        redirect: '/employeeManage/index',
        name: "employeeManage'",
        meta: {title: '员工管理', classify: 'employeeManage', icon: 'employeeManage', group:'admin', table:'user',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/employeeManage/index'),
                name: 'employeeManageIndex',
                meta: {title: '员工管理', classify: 'employeeManage', icon: 'employeeManage', group:'admin', table:'user',auditStatus:1, isOpen: false, isShow: 1}
            },

            {
                path: 'detail/:id',
                component: () => import('@/views/employeeManage/detail.vue'),
                name: 'employeeManageDetail',
                meta: {title: '员工详情', classify: 'employeeManage', icon: 'employeeManage', group:'admin', table:'user',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/employeeRecharge',
        component: platform,
        redirect: '/employeeRecharge/index',
        name: "employeeRecharge'",
        meta: {title: '员工福利发放', classify: 'employeeRecharge', icon: 'employeeRecharge', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/employeeRecharge/index'),
                name: 'employeeRechargeIndex',
                meta: {title: '选择一个员工福利发放计划', classify: 'employeeRecharge', icon: 'employeeRecharge', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'list/:plan_id',
                component: () => import('@/views/employeeRecharge/list.vue'),
                name: 'employeeManageList',
                meta: {title: '员工福利发放', classify: 'employeeRecharge', icon: 'employeeRecharge', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'record/:id',
                component: () => import('@/views/employeeRecharge/record.vue'),
                name: 'employeeManageRecord',
                meta: {title: '员工福利充值记录', classify: 'employeeRecharge', icon: 'employeeRecharge', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/employeesOrder',
        component: platform,
        redirect: '/employeesOrder/index',
        name: "employeesOrder'",
        meta: {title: '员工福利支付订单', classify: 'employeesOrder', icon: 'employeesOrder', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/employeesOrder/index'),
                name: 'employeesOrderIndex',
                meta: {title: '员工订单', classify: 'employeesOrder', icon: 'employeesOrder', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/employeesOrder/detail.vue'),
                name: 'employeesOrderDetail',
                meta: {title: '员工订单详情', classify: 'employeesOrder', icon: 'employeesOrder', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/employeeRechargeRecord',
        component: platform,
        redirect: '/employeeRechargeRecord/record',
        name: "employeeRechargeRecord'",
        meta: {title: '员工福利发放记录', classify: 'employeeRechargeRecord', icon: 'employeeRechargeRecord', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'record',
                component: () => import('@/views/employeeRecharge/record.vue'),
                name: 'employeeManageRecord',
                meta: {title: '员工福利充值记录', classify: 'employeeRechargeRecord', icon: 'employeeRechargeRecord', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
]

const adminRoutesCard = [
    {
        path: '/card',
        component: platform,
        redirect: '/card/index',
        name: "card",
        meta: {title: '生成充值卡', classify: 'card', icon: 'card', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/card/index'),
                name: 'cardIndex',
                meta: {title: '生成充值卡', classify: 'card', icon: 'card', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/cardPay',
        component: platform,
        redirect: '/cardPay/index',
        name: "cardPay",
        meta: {title: '充值卡支付记录', classify: 'cardPay', icon: 'cardPay', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/card/pay'),
                name: 'cardPay',
                meta: {title: '充值卡支付记录', classify: 'cardPay', icon: 'cardPay', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/card/detail'),
                name: 'cardPayDetail',
                meta: {title: '充值卡支付记录详情', classify: 'cardPay', icon: 'cardPay', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
]

const adminRoutesGiftBag = [
    {
        path: '/bag',
        component: platform,
        redirect: '/bag/index',
        name: "bag",
        meta: {title: '福利礼包', classify: 'bag', icon: 'bag', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/bag/index'),
                name: 'bagIndex',
                meta: {title: '福利礼包', classify: 'bag', icon: 'bag', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'order',
                component: () => import('@/views/bag/order'),
                name: 'bagOrder',
                meta: {title: '福利礼包订单', classify: 'bagOrder', icon: 'bagOrder', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/bag',
        component: platform,
        redirect: '/bag/order',
        name: "bagOrder",
        meta: {title: '福利礼包订单', classify: 'bagOrder', icon: 'bagOrder', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'order',
                component: () => import('@/views/bag/order'),
                name: 'bagOrder',
                meta: {title: '福利礼包订单', classify: 'bagOrder', icon: 'bagOrder', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/bag',
        component: platform,
        redirect: '/bag/pay',
        name: "bagPay",
        meta: {title: '福利礼包支付记录', classify: 'bagPay', icon: 'bagPay', group:'admin',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'pay',
                component: () => import('@/views/bag/pay'),
                name: 'bagPay',
                meta: {title: '福利礼包支付记录', classify: 'bagPay', icon: 'bagPay', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/bag/detail'),
                name: 'bagDetail',
                meta: {title: '福利礼包支付详情', classify: 'bag', icon: 'bag', group:'admin',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'orderDetail/:order_id',
                component: () => import('@/views/bag/orderDetail.vue'),
                name: 'bagOrderDetail',
                meta: {title: '福利礼包订单详情', classify: 'bagOrder', icon: 'bagOrder', role:3,level:4, affix: true, isShow: 1}
            },
        ]
    },
]

const adminRoutesTool = [
    {
        path: '/import',
        component:platform,
        redirect: '/import/index',
        name: "import",
        meta: { title: '导入数据', classify: 'import', icon: 'import', affix: true,auditStatus:1, isShow: 0 ,role:10},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/import/index'),
                name: 'importIndex',
                meta: { title: '导入数据', classify: 'import', icon: 'import', affix: true,auditStatus:1, isShow: 0 ,role:10}
            },
            {
                path: 'table',
                component: () => import('@/views/import/table'),
                name: 'importTable',
                meta: { title: '导入数据', classify: 'import', icon: 'import', affix: true,auditStatus:1, isShow: 0 ,role:10}
            }
        ])
    },
    {
        path: '/invited',
        component: platform,
        redirect: '/invited/index',
        name: "invited",
        meta: {title: '邀请码', classify: 'invited', icon: 'invited', group:'admin',auditStatus:1, isOpen: false, isShow: 0},
        children: [
            {
                path: 'index',
                component: () => import('@/views/invited/index'),
                name: 'invitedIndex',
                meta: {title: '邀请码', classify: 'invited', icon: 'invited', group:'admin',auditStatus:1, isOpen: false, isShow: 0}
            },
        ]
    },
    {
        path: '/address',
        component: platform,
        redirect: '/address/index',
        name: "address",
        meta: {title: '收货地址', classify: 'address', icon: 'address', group:'admin',auditStatus:3, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/address/index'),
                name: 'addressIndex',
                meta: {title: '收货地址', classify: 'address', icon: 'address', group:'admin',auditStatus:3, isOpen: false, isShow: 1}
            },
        ]
    },

    {
        path: '/teamInfo',
        component: platform,
        redirect: '/teamInfo/index',
        name: "teamInfo",
        meta: {title: '主体信息', classify: 'teamInfo', icon: 'teamInfo', group:'admin', isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/teamInfo/index'),
                name: 'teamInfoIndex',
                meta: {title: '主体信息', classify: 'teamInfo', icon: 'teamInfo', group:'admin',auditStatus:3, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/my',
        component: platform,
        redirect: '/my/index',
        name: "my",
        meta: {title: '个人信息', classify: 'my', icon: 'my', group:'admin',auditStatus:3, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/my/index'),
                name: 'myIndex',
                meta: {title: '个人信息', classify: 'my', icon: 'my', group:'admin',auditStatus:3, isOpen: false, isShow: 1}
            },
        ]
    },
]

const extractOrderRoutes = [
    {
        path: '/extractOrder',
        component: platform,
        redirect: '/extractOrder/index',
        name: "extractOrder'",
        meta: {title: '礼包提货订单记录', classify: 'extractOrder', icon: 'extractOrder', group:'admin', table:'extractOrder',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/extractOrder/index'),
                name: 'extractOrderIndex',
                meta: {title: '礼包提货订单', classify: 'extractOrder', icon: 'extractOrder', group:'admin', table:'extractOrder',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/extractOrder/detail.vue'),
                name: 'extractOrderDetail',
                meta: {title: '礼包提货订单详情', classify: 'extractOrder', icon: 'extractOrder', group:'admin', table:'extractOrder',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/extractWithdrawal',
        component:platform,
        redirect: '/extractWithdrawal/index',
        name: "extractWithdrawal",
        meta: { title: '礼包换钱订单记录', classify: 'extractWithdrawal', icon: 'extractWithdrawal', table:'extractWithdrawal' ,auditStatus:1,isOpen: false, isShow: 1 },
        children: [
            {
                path: 'index',
                component: () => import('@/views/extractWithdrawal/index'),
                name: 'extractWithdrawalIndex',
                meta: { title: '礼包换钱订单', classify: 'extractWithdrawal', icon: 'extractWithdrawal', table:'extractWithdrawal', auditStatus:1,isOpen: false, isShow: 1 }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/extractWithdrawal/detail.vue'),
                name: 'extractWithdrawalDetail',
                meta: {title: '礼包换钱订单详情', classify: 'extractWithdrawal', icon: 'extractWithdrawal', table:'extractWithdrawal', auditStatus:1,isOpen: false, isShow: 1}
            },
        ]
    },
    {
        path: '/extractBean',
        component:platform,
        redirect: '/extractBean/index',
        name: "extractBean",
        meta: { title: '换豆订单记录', classify: 'extractBean', icon: 'extractBean', table:'extractBean' ,auditStatus:1,isOpen: false, isShow: 1 },
        children: [
            {
                path: 'index',
                component: () => import('@/views/extractBean/index'),
                name: 'extractBeanIndex',
                meta: { title: '换豆订单', classify: 'extractBean', icon: 'extractBean', table:'extractBean', auditStatus:1,isOpen: false, isShow: 1 }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/extractBean/detail.vue'),
                name: 'extractBeanDetail',
                meta: {title: '换豆订单详情', classify: 'extractBean', icon: 'extractBean', table:'extractBean', auditStatus:1,isOpen: false, isShow: 1}
            },
        ]
    }
]


const withdrawalRoutes = [
    {
        path: '/withdrawal',
        component: platform,
        redirect: '/withdrawal/index',
        name: "withdrawal'",
        meta: {title: '换钱订单记录', classify: 'withdrawal', icon: 'withdrawal', group:'admin', table:'withdrawal',auditStatus:1, isOpen: false, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/withdrawal/index'),
                name: 'withdrawal',
                meta: {title: '提货订单', classify: 'withdrawal', icon: 'withdrawal', group:'admin', table:'withdrawal',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/withdrawal/detail.vue'),
                name: 'withdrawalDetail',
                meta: {title: '提货订单详情', classify: 'withdrawal', icon: 'withdrawal', group:'admin', table:'withdrawal',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    }
]

const systemMessageRoutes = [
    {
        path: '/systemMessages',
        component: platform,
        redirect: '/systemMessages/index',
        name: "systemMessages",
        meta: {title: '系统消息', classify: 'systemMessages', icon: 'systemMessages', group:'admin', table:'systemMessages',auditStatus:1, isOpen: false, isShow: 0},
        children: [
            {
                path: 'index',
                component: () => import('@/views/systemMessages/index'),
                name: 'systemMessagesIndex',
                meta: {title: '系统消息订单', classify: 'systemMessages', icon: 'systemMessages', group:'admin', table:'systemMessages',auditStatus:1, isOpen: false, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/systemMessages/detail.vue'),
                name: 'systemMessagesDetail',
                meta: {title: '系统消息详情', classify: 'systemMessages', icon: 'systemMessages', group:'admin', table:'systemMessages',auditStatus:1, isOpen: false, isShow: 1}
            },
        ]
    },
]


//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj){
    let index = arrayObj.findIndex((item)=>{
        return item["meta"]["isShow"] === 0
    })
    if(index>-1){
        arrayObj.splice(index, 1);
    }
    return arrayObj
}

export {
    adminRoutesIndex,
    adminRoutesOrder,
    adminRoutesEmployee,
    adminRoutesCard,
    adminRoutesGiftBag,
    extractOrderRoutes,
    withdrawalRoutes,
    systemMessageRoutes,
    adminRoutesTool
}