<template>
  <div class="sidebar" id="sidebar">
    <div class="site-name" data-path="/" @click="_jump">{{ systemConfig.short_name||'' }}</div>

    <ul class="main-nav">
      <li v-for="(item,index) in adminRoutesIndex" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

<!--    <div class="hr"></div>-->
    <ul class="main-nav">
      <li v-for="(item,index) in adminRoutesOrder" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in adminRoutesEmployee" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in adminRoutesCard" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in adminRoutesGiftBag" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in withdrawalRoutes" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in extractOrderRoutes" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>

<!--    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in systemMessageRoutes" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>-->

    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in adminRoutesTool" :key="index">
        <router-link :to="{name:item.name}" v-if="item.meta.isShow">{{ item.meta.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {adminRoutesIndex, adminRoutesOrder, adminRoutesEmployee,adminRoutesCard,adminRoutesGiftBag,extractOrderRoutes,withdrawalRoutes,systemMessageRoutes,adminRoutesTool,} from '@/router'
import {_data, _methods, util} from '@/common/public'

export default {
  name: 'PageSidebar',
  data() {
    return {
      ..._data,
      routes: [],
      adminRoutesIndex:adminRoutesIndex,
      adminRoutesOrder:adminRoutesOrder,
      adminRoutesEmployee:adminRoutesEmployee,
      adminRoutesCard:adminRoutesCard,
      adminRoutesGiftBag:adminRoutesGiftBag,
      adminRoutesTool:adminRoutesTool,
      withdrawalRoutes:withdrawalRoutes,
      systemMessageRoutes:systemMessageRoutes,
      extractOrderRoutes:extractOrderRoutes,
      systemRoutes:[],
      curRouteName: '',
      systemConfig: {
        short_name:''
      }
    }
  },
  created() {
    console.log('this.$route', this.$route)
    this.curRouteName = this.$route.name
    this.systemConfig = util.getLocalStorage('systemConfig',{})
  },
  methods: {
    ..._methods,
    _jump(e) {
      let {path} = e.target.dataset;
      this.$router.push({
        path: path
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 200px;
  background: #0365FA;
  color: #fff;
  font-size: 16px;
  height: 100vh;
  overflow-y: auto;
}

.site-name {
  height: 70px;
  font-size: 26px;
  padding-left: 24px;
  margin-bottom: 8px;
  border-bottom: #2578F8 solid 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar {
  .sub-title {
    padding: 0 14px 10px;
    line-height: 1.5;
    border-left: #04D45A solid 2px;
    font-size: 18px;
  }
  .sub-title .small {
    font-size: 13px;
  }

  .hr {
    margin-top: 6px;
    margin-bottom: 6px;
    border-bottom: #2578F8 solid 1px;
  }

  a {
    font-size: 16px;
    color: #fff;
    display: block;
    line-height: 50px;
    padding: 0 20px;
    border-left: transparent solid 4px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
    &:hover{
      background: #2578F8;
    }
  }
}

.sidebar a.router-link-active {
  background: #2578F8;
  border-left-color: #04D45A;
}

.sidebar a.router-link-active:hover {
  background: #2578F8;
}

.selectYear {
  padding: 8px;
  margin-bottom: 8px;
}
</style>