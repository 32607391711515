/*!
 * @description: 数据验证插件 v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 * @param {object} regular 传入的验证规则 {number(val, data, item) {return /^-?[1-9][0-9]?.?[0-9]*$/.test(val)},...} 会和默认的验证规则合并，传入的验证规则会覆盖默认验证规则
 * @example 
 * import { DataValidation } from '../../utils/dataValidation.js';
 * const dataValidation = new DataValidation();
 * //表单验证 需要验证的数据 key 名称，要和 name 值对应
 * //rules 数组对象中可以 传递 正则，使用属性 rule:/^-?[1-9][0-9]*$/
    let rules = [
      { name: "real_name", checkType: "required", errorMsg: "真实姓名不能为空" },
      { name: "idCard", checkType: "idCard", errorMsg: "身份证号格式不对" },
      { name: "phone", checkType: "phone", errorMsg: "手机号格式不对" },
    ]

    let v_data = {
      real_name: this.data.real_name,
      idCard: this.data.idCard,
      phone: this.data.phone
    };
    let v = dataValidation.validate(v_data, rules);
    if (!v.isOK) {
      wx.showToast({ title: v.errorMsg, icon: 'none' });
      return
    }
*/
class DataValidation {
    constructor(regular = {}) {
        this._regular = Object.assign({}, {
            required(val) {
                return val === '' ? 0 : 1
            },
            number(val) {
                return /^-?[1-9][0-9]?.?[0-9]*$/.test(val)
            },
            int(val) {
                return /^-?[1-9][0-9]*$/.test(val)
            },
            phone(val) {
                return /^1[0-9]{10,10}$/.test(val)
            },
            tmPhone(val) {
                return /(^(\d{3,4}-)?\d{7,8})$|(1[0-9]{10,10})/.test(val)
            },
            email(val) {
                return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val)
            },
            idCard(val) {
                return /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(val)
            },
            password(val) {
                return /^.{6,16}$/.test(val)
            },
            inviteCode(val) {
                return /^[a-zA-Z0-9]{6,16}$/.test(val)
            },
            //统一社会信用代码查询
            creditCode(val) {
                return /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/.test(val)
            },
            //注意有效年是 从 1900 - 2099
            year(val) {
                return /((19|20)\d{2})/.test(val);
            },
            //注意有效年是 从 1900 - 2099
            ymd(val) {
                let reg = /^((((19|20)\d{2})-(0?[13-9]|1[012])-(0?[1-9]|[12]\d|30))|(((19|20)\d{2})-(0?[13578]|1[02])-31)|(((19|20)\d{2})-0?2-(0?[1-9]|1\d|2[0-8]))|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))-0?2-29))$/;
                return reg.test(val)
            },
            minLength(val, data, item) {
                console.log('minLength', val, data, item)
                return val.length >= item.minLength
            },
            maxLength(val, data, item) {
                console.log('maxLength', val, data, item)
                return val.length <= item.maxLength
            },
            minToMax(val, data, item) {
                return val.length >= item.minToMax[0] && val.length <= item.minToMax[1]
            }
        }, regular);

        this.results = [];
    }

    validate(data, rules) {
        let _item = null;
        for (let item of rules) {
            if (typeof data[item['name']] === 'undefined') {
                continue;
            }

            //TODO：暂时未做空对象的判断
            //如果是空数组
            if (Array.isArray(data[item['name']])) {
                console.log('Array', item['name'], data[item['name']])
                if (item['checkType'] == 'required') {
                    item['isOK'] = data[item['name']].length;
                }
            } else {
                //字符串的判断
                let value = this.trim(data[item['name']]);

                //如果自定义了正则, 使用自定义的
                if (typeof item['rule'] !== 'undefined' && item['rule'] !== '') {
                    this._regular[item['checkType']] = function (val) {
                        return item['rule'].test(val)
                    }
                }

                item['isOK'] = this._regular[item['checkType']](value, data, item)
            }

            _item = item;
            if (!item['isOK']) {
                break;
            }
        }

        return _item
    }

    isNumber(val) {
        let regPos = /^\d+(\.\d+)?$/; //非负浮点数
        let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
        if (regPos.test(val) || regNeg.test(val)) {
            return true;
        } else {
            return false;
        }
    }

    trim(str) {
        let _str = this.isNumber(str) ? '' + str : str;
        return _str.replace(/^(\s|\u00A0)+/, '').replace(/(\s|\u00A0)+$/, '');
    }
}

export {
    DataValidation
}