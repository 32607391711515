<template>
  <div class="if-browser" v-if="ifBrowsersShow">请更换 谷歌浏览器 或者 Edge 浏览器</div>
</template>

<script>
import {util} from "@/common/util";

export default {
  name: "ifBrowsersCom",
  data() {
    return {
      ifBrowsersShow:false
    }
  },
  mounted() {
    this.ifBrowsersShow = !util._browser().chrome
  },
}
</script>

<style scoped>
.if-browser{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 100000;
  padding: 100px 0;
  text-align: center;
  font-size: 24px;
  color: #fff;
}
</style>