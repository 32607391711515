<template>
  <OpenHeader :pageTitleDefault="pageTitle"></OpenHeader>
  <div class="main-open">
    <div class="main-open-bg2"></div>
    <router-view/>
  </div>

  <ifBrowsers></ifBrowsers>
</template>

<script>
import ifBrowsers from '../components/ifBrowsers/index.vue'
import OpenHeader from "./components/OpenHeader";
import {util} from "@/common/util";
export default {
  name: "openLayout",
  components: {
    ifBrowsers,
    OpenHeader
  },
  data() {
    return {
      pageTitle:"",
    }
  },
  created() {
    this.pageTitle = this.$route.meta.title
  },
  mounted() {
    document.getElementById("body").className = "body-height-100"

    this.ifBrowsersShow = !util._browser().chrome
  },
}
</script>

<style lang="scss" scoped>
@import '../css/_var.scss';
/*开放页面 登录 注册*/
.main-open {
  width: 100vw;
  height: calc(100vh - 90px);
  min-width: $minWidth;
  min-height: $minHeight;
  overflow: hidden;

  background: top 130% right 100% no-repeat,linear-gradient(#0c2a55, #1561a4 33.19%, #195691 81.51%, #144985);
  background-attachment: fixed;
  background-size: contain;
}

@media screen and (max-width: 1920px) {

}
</style>
