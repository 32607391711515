<template>
  <div class="site-footer">
    <div class="inbox footer-1">
      <ul>
        <li>
          <p class="img-icon-zp"></p>
          <div>
            <p class="h">正品保障</p>
            <p class="des">正品保障、提供发票</p>
          </div>
        </li>
        <li>
          <p class="img-icon-jg"></p>
          <div>
            <p class="h">超低价格</p>
            <p class="des">一手货源、低于市场价</p>
          </div>
        </li>
        <li>
          <p class="img-icon-wl"></p>
          <div>
            <p class="h">急速物流</p>
            <p class="des">如约发货、急速送达</p>
          </div>
        </li>
        <li>
          <p class="img-icon-sh"></p>
          <div>
            <p class="h">售后无忧</p>
            <p class="des">退换无忧，一对一客服</p>
          </div>
        </li>
        <li>
          <p class="img-icon-dz"></p>
          <div>
            <p class="h">专属定制</p>
            <p class="des">可提供私有定制，品牌策划</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="inbox footer-2">
      <div class="start">
        <logoCom></logoCom>
        <div class="tel-footer">
          <p class="phone">0371-61928888</p>
          <p>周一至周六 9:00-18:30</p>
          <p style="margin-top: 10px">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">豫ICP备13003810号-23</a>
          </p>
        </div>
      </div>
      <div class="middle">
        <dl>
          <dt>帮助中心</dt>
          <dd>
            <router-link to="/article/page/common">常见问题</router-link>
          </dd>
          <dd>
            <router-link to="/article/page/guide">下单指南</router-link>
          </dd>
          <dd>
            <router-link to="/article/page/afterSales">售后问题</router-link>
          </dd>
        </dl>
        <dl>
          <dt>支付/配送</dt>
          <dd><a>对公支付</a></dd>
          <dd><a>全国配送</a></dd>
          <dd><a>售后客服</a></dd>
        </dl>
        <dl>
          <dt>服务条款</dt>
          <dd>
            <router-link to="/article/page/privacyPolicy">隐私协议</router-link>
          </dd>
          <dd>
            <router-link to="/article/page/register">注册协议</router-link>
          </dd>
        </dl>
        <dl>
          <dt>沃福利</dt>
          <dd>
            <router-link to="/article/page/about">关于我们</router-link>
          </dd>
          <dd>
            <router-link to="/article/page/contact">联系我们</router-link>
          </dd>
          <dd>
            <router-link to="/article/page/cooperation">招商合作</router-link>
          </dd>
        </dl>
      </div>
      <!--<div class="end">
        <p class="qrcode"><img src="../../images/qrcode.jpg"></p>
        <p>扫码关注沃福利公众号</p>
     </div>-->
    </div>
  </div>

  <fixTools></fixTools>
</template>

<script>
import {_data, _methods} from '@/common/public'
import logoCom from "@/components/logo/logo.vue";
import fixTools from "@/components/fixTools/fixTools.vue";

export default {
  props: {},
  components: {
    logoCom,
    fixTools
  },
  data() {
    return {
      ..._data,
      systemConfig: {
        title: "智业福利网"
      },
      userInfo: {realname: '', nickname: '', username: ''},
    }
  },
  created() {
  },
  methods: {
    ..._methods,
  },
}
</script>

<style lang="scss" scoped>
.site-footer {
  margin-top: 26px;
  background-color: #fff;
}

.footer-1 {
  height: 130px;
  background-color: #fff;
  display: flex;
  padding: 0 40px;
  box-sizing: border-box;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    li {
      display: flex;
      align-items: center;

      [class^='img-icon-'] {
        width: 49px;
        height: 49px;
        background-image: url(../../images/img-icon.png);
        background-repeat: no-repeat;
        margin-right: 10px;
      }

      .img-icon-dz {
        background-position: 0 0;
      }

      .img-icon-jg {
        background-position: 0 -49px;
      }

      .img-icon-sh {
        background-position: 0 -98px;
      }

      .img-icon-wl {
        background-position: 0 -147px;
      }

      .img-icon-zp {
        background-position: 0 -196px;
      }

      .h {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      .des {
        color: #999;
        font-size: 12px;
      }
    }
  }
}

.footer-2 {
  border-top: #ddd solid 1px;
  padding: 36px 0;

  display: flex;

  .start {
    width: 260px;
  }

  .middle {
    flex: 1;
    display: flex;
    justify-content: space-between;

    dl {
      width: 25%;
    }

    dt {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    dd {
      line-height: 34px;

      a {
        font-size: 14px;
      }
    }
  }

  .end {
    width: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    color: #666;
  }


  .qrcode {
    width: 132px;
    height: 132px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.tel-footer {
  margin-top: 14px;
  line-height: 1.5;

  .phone {
    font-size: 22px;
  }
}


.footer-links {
  padding: 20px 0;
  text-align: center;

  a {
    display: inline-block;
    margin: 0 8px;
  }
}
</style>
