import {$http} from '@/common/axios';
import {ServicesGlobal} from './services.js';

class SystemServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    details(data={}){
        return $http.get(`${this.path.www}/system/details`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
}


export default (new SystemServices())