<template>
  <div class="fix-tools">
    <a :href="qqLink" class="item tool-qq" title="单击QQ交流"
       target="_blank">
      <div class="fn-dib fix-tools-item-in">
        <p><i class="icon-qq f-icon"></i></p>
        <p class="txt">QQ客服</p>
      </div>
    </a>
    <a href="javascript:;" class="item tool-go-top" title="返回顶部" @click="scrollToTop()">
      <div class="fn-dib fix-tools-item-in">
        <i class="iconfont icon-arrow-up"></i>
      </div>
    </a>
  </div>

</template>

<script>
import {util} from '@/common/util'
import Services from "@/services/orderPo";

export default {
  name: 'fixToolsCom',
  props: {},
  data() {
    return {
      contactsShow: false,
      customer_phone: '',
      customer_wx: '',
      qq:'',
      qqLink:'',
      timer:0,
      oScroll:false
    }
  },
  created() {
    this.contacts()
  },
  mounted(){
    this.timer = null;
    this.oScroll = true;
    //滚动条事件,触发时清空定时器
    window.onscroll = function(){
      if(!this.oScroll){
        clearInterval(this.timer);
      }
      this.oScroll = false;
    }
  },
  methods: {
    scrollToTop(){
      clearInterval(this.timer);
      //加入定时器让他又快到慢滚动到顶部
      this.timer = setInterval(()=>{
        //获取当前scrollTop的高度位置（兼容ie和chrom浏览器）
        let oTop = document.documentElement.scrollTop || document.body.scrollTop;
        //设置速度由快到慢
        let isPeed = Math.floor(-oTop / 7);
        document.documentElement.scrollTop = document.body.scrollTop = oTop + isPeed;
        this.oScroll = true;
        if(oTop === 0){
          clearInterval(this.timer);
        }
      },30);

     /* const element = document.getElementById("body");
      element.scrollIntoView({
        behavior: 'smooth',    //平滑滚动，爽，其他还有 instant
        block: 'start'  //元素到页面顶部，其他还有 end, center
      })*/
    },
    contacts() {
      this.contactsShow = true
      Services.getSystemInfo().then(res => {
        if (res.Flag) {
          this.qq = res.Data.find(item => {
            return item.varname === "qq"
          })
          this.qqLink = `//wpa.qq.com/msgrd?v=3&uin=${this.qq.value}&site=qq&menu=yes`

          let customer_phone = res.Data.find(item => {
            return item.varname === "customer_phone"
          })
          this.customer_phone = util.stringToArray(customer_phone.value, "|", "@")
          console.log(this.customer_phone)

          let customer_wx = res.Data.find(item => {
            return item.varname === "customer_wx"
          })
          this.customer_wx = util.stringToArray(customer_wx.value, "|", "@")
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.fix-tools {
  width: 60px;
  position: fixed;
  right: 0;
  bottom: 2.1rem;
  z-index: 100;
}

.fix-tools a {
  display: block;
  width: 100%;
  padding: 5px 0 8px;
  text-align: center;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
  background: rgba(225, 37, 27, 0.8);
  color: #fff;
  border-bottom: rgba(255, 255, 255, 0.3) solid 1px;
}

.fix-tools .txt {
  font-size: 12px;
  margin-top: 5px;
}

.fix-tools .f-icon, .fix-tools .iconfont {
  font-size: 28px;
}

.fix-tools a:hover {
  background: rgba(225, 37, 27, 0.7);
  color: #fff;
}

.fix-tools .tool-go-top {
  height: 54px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;

  .fix-tools-item-in {
    line-height: normal;
    vertical-align: middle;
  }

  .icon-arrow-up {
    font-size: 24px;
    position: relative;
    top: -3px;
  }
}

.go-top {
  position: fixed;
  right: 12rem;
  bottom: 12rem;
  z-index: 999;
  cursor: pointer;
  color: #0070c9;
}

.go-top i {
  font-size: 68px;
}

.article-qrcode {
  display: none;
}
</style>