/*
 * @description: 公用的 data 和 methods v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 */
import {util} from './util.js';
import {_OPTIONS} from './options.js';
import {_CONFIG} from '@/config/config';
import {_rules} from './rules.js';
import SystemServices from "@/services/system";

let _data = {
    _CONFIG: _CONFIG,
    showPop: false,
    showNoData: false,
    userInfo: {},
    q: "",
    by: "",
    byPrice: "",
    bySales: "",
    listDataConfig: {
        params: {
            pageSize: 20,
            page: 1,
        }
    },
    paginationLayout: 'prev, pager, next,total',
    listServices: null,
    listData: [],
    groupsData: null,
    pageCount: 1,
    dataListCount: 0,
    loadingPages: true,
    loadMoreLoading: true,
    dataInit: true,
    pageEnd: false,
    isLoad: true,
    Loading: false,
    uploadData: {uploadTo: 'local', uploadDir: 'images'},
    uploadAction: `${_CONFIG.host}/admin/upFile`,
    uploadHeaders: {
        Authorization: localStorage.getItem('TOKEN') || 'head'
    },
    dot: '---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------',
};

let _methods = {
    init() {
        this._OPTIONS = _OPTIONS;
        this.userInfo = this.getLocalStorage("userInfo");
        this.curRoute = this.getCurRoute();
        console.log("userInfo", this.userInfo);
    },
    initBy() {
        this.q = this.$route.params.q || ''
        this.by = this.$route.query.by || ''
        if (this.by.indexOf("sales_") > -1) {
            this.bySales = this.by
        }
        if (this.by.indexOf("price_") > -1) {
            this.byPrice = this.by
        }
    },
    getApp(key) {
        let obj = this.GlobalData;
        if (key && typeof obj[key] === 'undefined') {
            return null
        }
        return key ? obj[key] : obj;
    },
    setApp(obj = {}) {
        this.GlobalData = util._extends(true, this.GlobalData, obj);
        return this;
    },
    uploadInit(upConfig){
        this.uploadData = upConfig.uploadData||{uploadTo:'local',uploadDir:'images'}
        this.uploadAction = upConfig.uploadAction||`${_CONFIG.host}/admin/upFile`
        this.uploadHeaders = {
            Authorization:localStorage.getItem('TOKEN') || 'head'
        }
    },
    getLocalStorage(key, defaultValue = {}) {
        return util.getLocalStorage(key, defaultValue)
    },

    getRules(requiredFields) {
        let requiredFieldsArray = requiredFields.split(',');
        let requiredRules = [];
        for (let i = 0; i < requiredFieldsArray.length; i++) {
            let _obj = _rules.find((m) => {
                return m.name === requiredFieldsArray[i];
            });
            typeof _obj !== 'undefined' && requiredRules.push(_obj)
        }
        return requiredRules;
    },
    getRequiredFields() {
        let requiredEls = document.querySelectorAll('[required]');
        let names = [];
        for (let item of requiredEls) {
            !item.getAttribute('data-not') && names.push(item.getAttribute('name'))
        }
        return names.join(',');
    },
    getRulesDiff(names, rules) {
        let _rules = rules || this.rules || [];
        let _names = typeof names === 'string' ? names.split(',') : names;
        let arrayError = [];
        console.log("测试规则是否存在：\n");
        for (let item of _names) {
            let _rule = _rules.find((m) => {
                return item === m.name;
            });
            if (_rule) {
                let _rule_string = JSON.stringify(_rule);
                console.info(`name：${item}->rule：${_rule_string}`);
            } else {
                let item_string = item ? item : 'null';
                console.error(`name：${item_string}->rule：undefined`);
                arrayError.push(item)
            }
        }
        console.log(arrayError.join(","))
    },
    submitFormBefore() {
        //最后一次拦截
        return true
    },
    submitFormAfter() {
    },

    jumpPath(url) {
        this.$router.push({path: url})
    },
    jumpGoodsDetail(item) {
        this.$router.push({path: `/goods/detail/${item.Id}`})
    },
    jumpPackageDetail(item) {
        this.$router.push({path: `/package/detail/${item.Id}`})
    },
    jumpArticleDetail(item) {
        this.$router.push({path: `/article/detail/${item.Id}`})
    },
    jumpNoticeDetail(item) {
        this.$router.push({path: `/article/notice/${item.Id}`})
    },

    getCurRoute() {
        return this.$route;
    },
    cdnFilePath(path, defaultPath = "") {
        let systemConfig = util.getLocalStorage('systemConfig', {})
        let cdn = systemConfig.cdn || ''

        let _path = path ? path : defaultPath
        if (!_path) {
            return ""
        }
        if (_path.indexOf("//") > -1) {
            return _path
        }

        return `${cdn}/${_path}`
    },

    pageBottomEvents(obj) {
        console.log('pageBottomButtons', obj)
        let value = obj.value;
        let eventName = 'pageBottomEvent' + value.charAt(0).toUpperCase() + value.slice(1)
        if (typeof eventName === 'undefined') {
            console.error(`找不到 ${eventName} 这个方法`)
            return
        }
        this[eventName](obj)
    },
    formatYmd(date = '') {
        if (!date) {
            return '';
        }
        return util.formatDate("YYYY-MM-DD hh:mm", date);
    },
    formattingYMD(value) {
        if (value === "" || value.indexOf("0001-01-01") > -1 || value.indexOf("0000-00-00") > -1) {
            return ""
        }
        const date = new Date(value);
        let Y = date.getFullYear();
        let M =
            date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
        let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

        //return Y + "-" + M + "-" + D;改value-format没用，我也不知道为什么
        //最后传给后台的格式YYYYMMDD
        return Y + '-' + M + '-' + D;
    },
    formatOption(value, optionName) {
        if (typeof _OPTIONS[optionName] === 'undefined') {
            return '';
        }
        let obj = _OPTIONS[optionName].find((item) => {
            return item.value == value
        });
        return obj ? obj.label || obj.name : '';
    },
    formattingTime(value) {
        if (value === undefined) {
            return ""
        }
        if (value === "" || value.indexOf("0001-01-01") > -1 || value.indexOf("0000-00-00") > -1) {
            return ""
        }
        let date = new Date(value)
        let year = date.getFullYear();
        let month = (date.getMonth() + 1);
        if (month < 10) {
            month = '0' + month;
        }
        let day = date.getDate();
        if (day < 10) {
            day = '0' + day;
        }
        let hours = date.getHours();
        if (hours < 10) {
            hours = '0' + hours;
        }
        let minutes = date.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let seconds = date.getSeconds();
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        let FormattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        return FormattedDateTime;
    },

    formatCard(value) {
        let end = value.substring(value.length - 3)
        return value.split("-")[0] + '******' + end
    },

    /*
    * 获取数组对象的 names 数组  getArrayObjNames 获取其中的 name 数组，用于 xAxis_data 和 legend_data
    * [
        {
            value: 60,
            name: '博士'
        },
        {
            value: 80,
            name: '硕士'
        },
        {
            value: 200,
            name: '本科'
        },
    ]
    * */
    getArrayObjNames(arrayObj) {
        let keys = [];
        for (let i = 0; i < arrayObj.length; i++) {
            keys.push(arrayObj[i]['name']);
        }
        return keys;
    },

    /*
    * 分组的柱状或者折线数据格式转换 getGroupData
    * json  服务端返回的 json，约定格式查看下方 JSON: 段的解释
    * seriesTpl={} / []  数据展示的模板，比如 柱状的每个柱子，折线的每个折线都是可以有不同的外观，所以要各自配置模板，
     服务端返回数据的数据格式如：
        {
            "Flag":1,
            "Msg":"OK",
            "Code":200,
            "Data":[{...},{...}...] // json 数据格式约定如下
        }
     JSON: 服务端约定 json 的数据格式：
     classify 必选，x 轴的分类方式
     data 必选，
     name 必选，
     value 必选，
     jumpUrl 自定义的参数，可以自定义多个，用于图形和服务端事件交互
     示例：
     [
        {
            classify: '煤炭开采',
            data: [
                {
                    name: '2018',
                    value: 320,
                    jumpUrl:'https://www.xxx.com/s?q=2018&classify=1'
                },
                {
                    name: '2019',
                    value: 120,
                    jumpUrl:'https://www.xxx.com/s?q=2019&classify=1'
                },
                {
                    name: '2020',
                    value: 220,
                    jumpUrl:'https://www.baidu.com/s?q=2020&classify=1'
                }
            ]
        },
        {
            classify: '煤矿支护',
            data: [
                {
                    name: '2018',
                    value: 300,
                    jumpUrl:'https://www.xxx.com/s?q=2018&classify=2'
                },
                {
                    name: '2019',
                    value: 220,
                    jumpUrl:'https://www.xxx.com/s?q=2019&classify=2'
                },
                {
                    name: '2020',
                    value: 280,
                    jumpUrl:'https://www.baidu.com/s?q=2020&classify=2'
                }
            ]
        },
    * ]
    * 转换成 echart 需要如下格式，
    * [
        {
            name: '2018',
            type: 'bar',
            emphasis: {
                focus: 'series'
            },
            data: [{jumpUrl: "xxx",value: 300},{jumpUrl: "xxx",value: 320}],
        },
        {
            name: '2019',
            type: 'bar',
            emphasis: {
                focus: 'series'
            },
            data: [{jumpUrl: "xxx",value: 380},{jumpUrl: "xxx",value: 340}],
        },
        {
            name: '2020',
            type: 'bar',
            emphasis: {
                focus: 'series'
            },
            data: [{jumpUrl: "xxx",value: 420},{jumpUrl: "xxx",value: 360}],
        }
    ];
    * */
    getGroupData(json, seriesTpl = {}) {
        let seriesTplDataType = util.isArray(seriesTpl); //seriesTpl 可以使对象或者数组
        let xAxis_data = [];
        for (let item of json) {
            xAxis_data.push(item.classify)
        }

        let legend_data = [];
        let _data_temp = [];
        for (let i = 0; i < json[0].data.length; i++) {
            let _name = json[0].data[i].name;
            legend_data.push(_name);
            let _seriesTpl = seriesTplDataType ? seriesTpl[i] : seriesTpl;
            let _series_item = util.extend(true, {}, _seriesTpl, {
                name: _name,
                data: []
            });

            _data_temp.push(_series_item);
        }

        let _data_temp2 = [];
        for (let j = 0; j < _data_temp.length; j++) {
            let _temp = [];
            for (let k = 0; k < json.length; k++) {
                _temp.push(json[k].data[j]);
            }
            _data_temp2.push(_temp);

            _data_temp[j].data = _temp;
        }
        console.log('_data_temp', _data_temp);
        return {
            legend_data: legend_data,
            mAxis_data: xAxis_data,  //主轴 通常是 xAxis.data , 也有是有 y 轴的情况
            series: _data_temp,
        };

    },
    pieOption(config = {}) {
        return util.extend(true, {}, {
            title: {
                text: '某站点用户访问来源',
                subtext: '纯属虚构',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                show: false,
                orient: 'vertical',
                left: 'left',
                textStyle: {
                    color: '#fff',
                    fontSize: 12
                }
            },
            series: [
                {
                    label: {
                        color: "#83f352",
                        fontSize: 14,
                        fontWeight: 400,
                        textBorderWidth: 0,
                        textBorderColor: '#83f352',
                        formatter: '{b}\n{c} ({d}%)',
                        lineHeight: 22,
                    },
                    name: '访问来源',
                    type: 'pie',
                    radius: '60%',
                    center: ["50%", "50%"],
                    data: [],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }, config)
    },
    barOption(config = {}) {
        return util.extend(true, {}, {
            title: {
                textAlign: 'left',
                left: 18,
                top: 12,
                subtext: ``,
                subtextStyle: {
                    textAlign: 'right',
                    left: 'center'
                }
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '5%',
                top: '20%',
                containLabel: true
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} <br/> {c}'
            },
            legend: {
                textStyle: {
                    color: '#83f352',
                    fontSize: 12
                }
            },
            xAxis: {
                type: 'category',
                axisLine: {
                    lineStyle: {
                        color: '#83f352'
                    }
                },
                data: []// [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#83f352'
                    }
                },
            },
            series: [{
                data: [], // [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
                type: 'bar',
                itemStyle: {
                    emphasis: {
                        show: true,
                        color: '#6086fb'
                    }
                }
            }]
        }, config)
    },
    gaugeOption(config = {}) {
        return util.extend(true, {}, {
            series: [{

                title: {
                    offsetCenter: [0, '82%'],
                    fontSize: 18
                },
                type: 'gauge',
                min: 0,
                max: 100,
                progress: {
                    show: true,
                    width: 10
                },
                axisLine: {
                    lineStyle: {
                        width: 10
                    }
                },
                axisTick: {
                    show: true,
                    distance: 0,
                    length: 6,
                    lineStyle: {
                        color: '#666',
                        width: 1
                    }
                },
                splitLine: {
                    distance: 0,
                    length: 12,
                    lineStyle: {
                        width: 1,
                        color: '#666'
                    }
                },
                axisLabel: {
                    distance: 15,
                    color: '#333',
                    fontSize: 14
                },
                anchor: {
                    show: true,
                    showAbove: true,
                    size: 20,
                    itemStyle: {
                        borderWidth: 10
                    }
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 18,
                    offsetCenter: [0, '36%']
                },
                data: [{
                    name: '成绩评定',
                    value: 70
                }]
            }]
        }, config)
    },
    pieNest(config = {}) {
        return util.extend(true, {}, {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                show: false,
                textStyle: {
                    color: '#fff',
                    fontSize: 12
                },
                data: []//['专科', '本科', '研究生', '其他']
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    selectedMode: 'single',
                    radius: [0, '40%'],
                    label: {
                        position: 'inner',
                        fontSize: 14,
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        /*{value: 1548, name: '学士'},
                        {value: 775, name: '硕士'},
                        {value: 679, name: '博士', selected: true}*/
                    ]
                },
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: ['52%', '68%'],
                    labelLine: {
                        length: 12,
                    },
                    label: {
                        color: "#83f352",
                        fontSize: 14,
                        fontWeight: 400,
                        textBorderWidth: 0,
                        textBorderColor: '#83f352',
                        formatter: '{b}\n {c}\n{d}%',
                        lineHeight: 20,
                    },
                    data: [
                        /*{value: 1048, name: '专科'},
                        {value: 335, name: '本科'},
                        {value: 310, name: '研究生'},
                        {value: 251, name: '其他'},*/
                    ]
                }
            ]
        }, config)
    },

    popClose() {
        this.popShow = false
    },

    pageSearchEvent() {
        typeof this.pageSearchEventEmit !== 'undefined' && this.pageSearchEventEmit();
    },

    //处理分类 级别
    getSonsTree(arrayObj, iniPid = "0") {
        console.log('arrayObj', arrayObj)
        let _array = arrayObj
        let arrayTemp = []
        let fn = function (id, lev) {
            for (let i = 0; i < _array.length; i++) {
                if (id === _array[i]['pid']) {
                    _array[i]['lev'] = lev
                    arrayTemp.push(_array[i])
                    fn(_array[i]['Id'], lev + 1)
                }
            }
        }
        fn(iniPid, 0)
        console.log('arrayTemp', arrayTemp)
        return arrayTemp
    },
    unfoldData(arrayObj) {
        let array = []
        let fn = function (arr) {
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i]
                let chirld = item.chirld
                delete item.chirld
                array.push(item)
                if (chirld.length) {
                    fn(chirld)
                }
            }
            //array = array.concat(tempArray)
        }
        fn(arrayObj)

        /*let array_new = []
        for (let item of array){
            array_new.push(item)
            for(let j=0;j<array.length;j++){
                let index = array_new.findIndex(value=>{
                    return value.Id === item.Id
                })
                if(!index && array[j].pid === item.Id){
                    array_new.push(array[j])
                }
            }
        }*/

        return array
    },

    onUploadPreview(file) {
        console.log('onUploadPreview', file)
    },
    onUploadRemove(file, fileList) {
        console.log('onUploadRemove', file, fileList)
    },
    //自行实现 根据实际情况重写此方法
    onUploadSuccess(response, file, fileList) {
        console.log('onUploadSuccess', response, file, fileList)
        this.model.thumbnail = response.result
    },

    msg(msg, type, config) {
        let typeMap = {
            "true": "success",
            "false": "error",
            "1": "success",
            "0": "error",
            "-1": "warning",
        }

        let _config = util.extend({}, {
            message: msg,
            type: typeMap[type],
        }, config)

        this.$message(_config)
    },
    _msg(msg, type, config){
        this.msg(msg, type, config)
    },
    _confirm(msg = "提示", type='warning', config={}) {
        let _config = util.extend({}, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: type,
        },config)

        return this.$confirm(msg, 'Warning',_config).then((res) => {
                return res
            })
            .catch(() => {

            })
    },

    // tokenConfig->response.headers.config
    setYearQuarter(year, quarter, tokenConfig) {
        //配置前端使用
        localStorage.setItem('defaultYear', year);
        localStorage.setItem('defaultQuarter', quarter);
        //把配置传回给服务端
        localStorage.setItem('TOKENCONFIG', tokenConfig);
    },
    getYearQuarter(type = "") {
        let year = localStorage.getItem('defaultYear');
        let quarter = localStorage.getItem('defaultQuarter');
        console.log("getYearQuarter", year, quarter)
        if (!type) {
            return {
                year: year,
                quarter: quarter,
                yearQuarter: `${year}-${quarter}`
            }
        }

        if (type === 'y') {
            return year
        }
        if (type === 'q') {
            return quarter
        }
        if (type === 'y-q') {
            return `${year}-${quarter}`
        }
    },

    formSubmitValidate(formName, onSuccess = null) {
        return this.$refs[formName].validate().then(ok => {
            if (!ok) {
                return false
            }
            onSuccess && onSuccess()
            return ok
        }).catch((err) => {
            console.log(err)
        })
    },

    jumpByPath(path = '') {
        if (!path) {
            return
        }
        this.$router.push({path: path});
    },

    counterError(obj) {
        console.log("counterError", obj)
        this.msg(obj.msg, 0)
    },

    showLoginCom() {
        this.$refs.loginCom.show()
    },
    formatPrice(price, digits = 2) {
        return util.formatPrice(price, digits)
    },

    changeBy(by = '') {
        let value = this[by]
        if (by === 'byPrice') {
            this.bySales = ""
        } else if (by === 'bySales') {
            this.byPrice = ""
        }
        let routerPath = this.$route.path
        location.href = `/#${routerPath}?by=${value}`
        setTimeout(() => {
            location.reload()
        }, 100)
    },
    clearBy(by = '') {
        console.log("clearBy", this[by])
    },

    formatCartInfoKeys(sku, keys) {
        if (keys === "默认") {
            return "默认"
        }
        let sku_key = []
        let sku_array = []
        if (sku.indexOf('--!--') > -1) {
            sku_array = sku.split("--!--")
        } else {
            sku_array = sku.split("\n")
        }
        for (let item of sku_array) {
            sku_key.push(item.split("@")[0])
        }

        let keys_new = []
        let keys_array = keys.split("-##-")
        for (let i = 0; i < keys_array.length; i++) {
            keys_new.push(`<span>${sku_key[i]}：${keys_array[i]}</span>`)
        }
        return keys_new.join("、")
    },
    makeGoodsSpecifications(goodsDetails = {}, type = 'array') {
        let obj = {
            Id: "0",
            goods_id: goodsDetails.Id,
            inventory: goodsDetails.inventory,
            is_default: 1,
            keys: "默认",
            picture: goodsDetails.thumb,
            price: goodsDetails.price,
            default_price: goodsDetails.price,
            price_purchase: goodsDetails.price_purchase,
            source_goods_id: goodsDetails.source_goods_id,
            goods_type: goodsDetails.goods_type,
        }
        return type === "array" ? [obj] : obj
    },
    mustLogged() {
        let userInfo = util.getLocalStorage("userInfo", {Id: ""})
        if (!userInfo.Id) {
            this.msg("您还没有登录", 0)
            return false
        }
        return true
    },
    //更新 购物车数量 number 为 正 或者 负 +1 或者 -1
    cartCount(number) {
        let systemConfig = util.getLocalStorage('systemConfig', {cartCount: 0})
        console.log("systemConfig", systemConfig)
        systemConfig["cartCount"] = (systemConfig["cartCount"] - 0) + number
        if (systemConfig["cartCount"] < 0) {
            systemConfig["cartCount"] = 0
        }
        localStorage.setItem("systemConfig", JSON.stringify(systemConfig))
        document.getElementById("cartCount").innerText = systemConfig["cartCount"]
    },

    getSystemConfig() {
        return SystemServices.details().then(res => {
            localStorage.setItem('systemConfig', JSON.stringify(res.Data));
            localStorage.setItem('mainNav', JSON.stringify(res.otherData.mainNav));

            let getSonsTree = util.getSonsTree(res.otherData.classNav, '0')
            let categoryNavSonsTree = util.getSonsTree(
                res.otherData.classNav,
                "cal92v9uk5rq65dk94u0"
            )

            let level1 = []
            for (let item of categoryNavSonsTree) {
                if (item.lev === 0) {
                    item.child = []
                    item.cnameArray = item.cname.split("|")
                    item.pathWebArray = item.path_web.split("|")
                    level1.push(item)
                }
            }

            for (let item of level1) {
                let temp = []
                for (let m of getSonsTree) {
                    if (m.pid === item.Id) {
                        temp.push(m)
                    }
                }

                item.child = temp
            }

            localStorage.setItem('navSonsTree', JSON.stringify(getSonsTree));
            localStorage.setItem('classNav', JSON.stringify(level1));

            let getCategoryTree = util.getSonsTree(res.otherData.category, '0')
            localStorage.setItem('categoryTree', JSON.stringify(getCategoryTree));
        })
    },

    // 由 getUserLevelCount 请求获得员工级别后，进行一个数据处理 bag/index.vue  bag/order.vue
    makeUserLevelCount(data) {
        let roleMap = {1: "员工", 11: "非员工"}
        let levelMap = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J"]

        let obj = {}
        for (let i = 0; i < data.length; i++) {
            let item = data[i]
            let key = `${item.role}_${item.level}`
            let label = `${roleMap[item.role]}-${levelMap[item.level]}级`
            if (obj[key]) {
                obj[key] = {
                    label: label,
                    number: obj[key].number + 1
                }
            } else {
                obj[key] = {
                    label: label,
                    number: 1
                }
            }
        }

        let _array = []
        for (let key in obj) {
            _array.push({
                label: obj[key].label + `(${obj[key].number})`,
                value: key,
                number: obj[key].number
            })
        }

        return {
            obj: obj,
            array: _array
        }
    },

    //初始化分类列表
    initCategory() {
        //分类树
        let categoryTree = []
        let tree = util.getLocalStorage("categoryTree", [])
        for (let i = 0; i < tree.length; i++) {
            let item = tree[i]
            item.className = item.Id === this.cid ? `active item-${item.lev}` : `item-${item.lev}`
            if (item.lev === 0) {
                categoryTree.push(item)
            }
        }

        categoryTree.unshift({
            Id: "0",
            cname: "总排行",
            lev: 0,
            pid: "0",
        })
        return categoryTree
    },
    jumpCategory() {
        //this.$router.push({path:`/goods/top/${item.Id}`})
    }
};

let _watch = {
    /*"model.unitCode": function (newVal, oldVal) {
        let obj = this._OPTIONS.unit.find(item => {
            return item.value === newVal;
        });
        if (!obj) {
            return
        }
        this.model.unitName = obj.name;
        console.log('this.model.unitName', this.model.unitName)
    }*/
};

export {
    util,
    _data,
    _methods,
    _watch,
    _OPTIONS,
    _CONFIG
}