<template>
  <div class="site-tools">
    <div class="inbox">
      <div class="start">
        <router-link to="/" class="site-name">{{ systemConfig.title }}，{{ systemConfig.slogan }}</router-link>
      </div>
      <div class="end">
        <a class="col login-register" v-if="!userInfo.Id" @click="showLoginCom">登录</a>

        <router-link to="/admin/index" class="col user-name" v-if="userInfo.Id">
          HI，{{ userInfo.realname || userInfo.nickname || userInfo.username }}
        </router-link>

        <router-link to="/vpoOrder/index" class="col my-cart em" v-if="userInfo.Id">
          采购订单
        </router-link>

        <router-link to="/goods/cart" class="col my-cart em" v-if="userInfo.Id">
          我的进货单<var>(<span id="cartCount">{{systemConfig.cartCount}}</span>)</var>
        </router-link>

       <router-link to="/bag/index" class="col my-cart em" v-if="userInfo.Id">
          我创建的礼包
        </router-link>

        <span class="col switch-user" @click="logout" v-if="userInfo.Id">退出用户</span>
      </div>
    </div>
  </div>

  <div class="site-header">
    <div class="inbox">
      <div class="start">
        <logoCom></logoCom>
      </div>
      <div class="center">
        <form action=""  @submit.prevent="submitSearch" class="site-search">
          <div class="search-box">
            <div class="keyword-box">
              <i class="icon-search"></i>
              <input type="text" v-model="q" placeholder="输入关键字 搜索商品">
              <span class="search-clear" @click="searchClear" v-if="q"><i class="icon-close"></i></span>
            </div>
            <button type="submit" class="search-button">搜 索</button>
          </div>
        </form>

        <div class="hot-keys" style="display: none"></div>
      </div>
      <div class="end">
        <a class="col login-register" v-if="!userInfo.Id" @click="showLoginCom"><i class="icon-head"></i> 登录</a>
        <router-link to="/goods/top/0" class="col go-top"><i class="icon-rexiao1"></i> 排行榜</router-link>
      </div>
    </div>
  </div>

  <div class="main-nav">
    <div class="inbox">
      <div class="placeholder-category-title" data-title="产品分类"></div>
      <ul>
        <li v-for="(item,index) in mainNav" :key="index">
          <router-link :to="item.path_web" :key="randomId" class="item" :class="{'highlight':item.highlight}">{{ item.cname }}</router-link>
        </li>
      </ul>
    </div>
  </div>

  <loginCom ref="loginCom"></loginCom>
</template>

<script>
import {_data, _methods, util} from '@/common/public'
import logoCom from "@/components/logo/logo.vue";
import loginCom from "@/views/login/loginCom";

export default {
  components: {
    logoCom,
    loginCom
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    randomId () {
      return this.$route.fullPath + new Date()
    }
  },
  data() {
    return {
      ..._data,
      systemConfig: {
        title: "智业福利网",
        cartCount:0,
      },
      userInfo: {Id: "", realname: '', nickname: '', username: ''},
      mainNav:[],
      classNav:[],
      q:"",
      path:"",
      navHighlight:false,
    }
  },
  async created() {
    this.q = this.$route.params.q||''

    await this.getSystemConfig()
    this.initData()
  },
  mounted() {

  },
  methods: {
    ..._methods,
    initData(){
      this.userInfo = util.getLocalStorage('userInfo', {});
      this.systemConfig = util.getLocalStorage('systemConfig',{})
      this.mainNav = util.getLocalStorage('mainNav',[])

      for(let item of this.mainNav){
        item.highlight = this.navHighlightFn(item.path_web)
      }
    },
    logout() {
      localStorage.setItem("TOKEN", "")
      localStorage.setItem("userInfo", "")
      localStorage.setItem('userConfig', "");
      if(this.$route.name==="index"){
        location.reload()
      }else{
        this.$router.push({path: '/'});
      }
    },
    navHighlightFn(url){
      let path = this.$route.path||""
      let urlArray = url.split("?")
      return path.indexOf(urlArray[0])>-1
    },
    submitSearch(){
      if (this.q == ""){
        this.msg("搜索内容不能为空",0)
        return;
      }
      this.$router.push({path:`/goods/search/${this.q}`});
    },
    searchClear(){
      this.q = ""
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../css/_var.scss';

.site-tools {
  border-bottom: #eee solid 1px;
  background-color: rgb(54, 54, 54);
  color: #999;

  a {
    color: #999;

    &:hover {
      color: $primaryColor;
    }
  }

  .inbox {
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    display: flex;
  }

  .start {
    width: 200px;
  }

  .end {
    flex: 1;
    text-align: right;

    .col {
      margin-left: 14px;
    }

    .my-cart {
      position: relative;

      var {

      }
    }

    .badge-number {
      display: inline-block;
      position: static;
    }
  }
}

$siteHeaderHeight: 80px;
.site-header {
  background: #fff;
  height: $siteHeaderHeight;
  .inbox {
    display: flex;
    position: relative;
  }
  .start {
    width: 300px;
    height: $siteHeaderHeight;
    display: flex;
    align-items: center;
  }
  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11px;
    .site-search {
      width: 100%;
    }
    .hot-keys{
      width: 100%;
      margin-top: 5px;
      text-align: left;
    }
  }

  .end {
    text-align: right;
    width: 300px;
    height: $siteHeaderHeight;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;

    .col {
      margin-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    .login-register {
      padding: 0 10px;
    }

    [class^='icon-'] {
      font-size: 22px;
      margin-right: 6px;
      color: $primaryColor;
    }
  }

}

.search-box {
  display: flex;

  .keyword-box {
    flex: 1;
    position: relative;
  }

  input {
    width: 100%;
    /*border: #ff4000 solid 2px;*/
    border: $primaryColor solid 2px;
    border-radius: 4px;
    height: 40px;
    padding: 0 22px 0 40px;
    box-sizing: border-box;
    font-size: 16px;
    position: relative;
    z-index: 1;
  }

  .search-button {
    width: 100px;
    height: 40px;
    border: none;
    /*background-color: transparent;
    background-image: linear-gradient(90deg, #ff7e3e, #ff4000);*/
    background-color: $primaryColor;
    border-radius: 0 6px 6px 0;
    margin-left: -10px;
    color: #fff;
    font-size: 16px;
    position: relative;
    z-index: 3;
  }

  .icon-search {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: #999;
  }
  .search-clear{
    width: 30px;
    position: absolute;
    right: 14px;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon-close{
      margin-top: -2px;
      background-color: #eee;
      padding: 4px;
      border-radius: 50%;
      font-size: 10px;
    }
    &:hover{
      .icon-close{
        background-color: #dc0000;
        color: #fff;
      }
    }
  }
}

.site-header-placeholder {
  height: $siteHeaderHeight;
  margin-bottom: 16px;
}

.switch-user {
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: $primaryColor;
  }
}

.main-nav{
  height: 50px;
  background: #fff;
  margin-bottom: 16px;
  font-weight:400;
  .inbox{
    height: 100%;
    display: flex;
  }

  ul{
    height: 100%;
    display: flex;
    li{
      height: 100%;
    }
    .item{
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 0 25px;
    }
  }
}
</style>
