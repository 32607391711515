/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
const _CONFIG = Object.assign({
    "debug": true,
    "host": process.env.VUE_APP_HOST,
    "apiPath": process.env.VUE_APP_API,
    "api": {
        "www": process.env.VUE_APP_API + "/www",
        "admin": process.env.VUE_APP_API + "/admin",
    },
    "apiOSSPath":"https://zhiyefuliwang.oss-cn-beijing.aliyuncs.com/",
    "siteUrl": 'http://xx.xxx.com',
    "cdn": "https://zhiyefuliwang.oss-cn-beijing.aliyuncs.com/",
    "rootPath": "",
    "siteName": "睿桃",
    "systemFullName": "睿桃",
    "shortName": "睿桃",
    "slogan":"一个有温度的福礼社",
    "column": []
}, {});

export {_CONFIG}