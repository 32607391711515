import {createApp} from 'vue'
import App from './App.vue'

import {router} from '@/router'

import ElementPlus,{ ElMessage } from 'element-plus'

import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import './css/style.dev.scss';

// 导航守卫文档：https://next.router.vuejs.org/zh/guide/advanced/navigation-guards.html#%E5%85%A8%E5%B1%80%E8%A7%A3%E6%9E%90%E5%AE%88%E5%8D%AB
const whiteList = ['/login']
router.beforeEach((to, from, next) => {
    console.log("toto", to)
    if (to.meta.isOpen) {
        next()
        return
    }

    if (whiteList.indexOf(to.path) > -1) {
        next()
        return
    } else {
        if (!localStorage.getItem('TOKEN') || !localStorage.getItem('userInfo')) {
            localStorage.setItem("TOKEN", "")
            localStorage.setItem("userInfo", "")
            localStorage.setItem('userConfig', "");
            localStorage.setItem('AuditStatus', "");
            //next({path: '/login'})
            return
        } else {
            let auditStatus = localStorage.getItem('AuditStatus')||10;
            console.log("auditStatus--",auditStatus,to.meta.auditStatus,auditStatus-0 < to.meta.auditStatus-0)
            if(auditStatus-0 <= to.meta.auditStatus-0){
                next()
                return
            }else{
                let auditMap = ["","通过","审核中","审核失败"]
                ElMessage({
                    message: `主体审核状态为“${auditMap[auditStatus]}”，暂时还不能访问`,
                    type: 'error',
                    duration: 4 * 1000
                })
                next({path: '/teamInfo/index'})
                return
            }
        }
    }
})


router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
})


createApp(App)
    .use(router)
    .use(ElementPlus,{
        locale: zhCn,
    })
    .mount('#app')
