/**
 * Created by Administrator on 2020/11/26.
 */
import {$http} from '@/common/axios';
import {ServicesGlobal} from './services.js';

class LoginServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    // 登录
    login(data) {
        let params = this.postParams(data);
        return $http.post(`{admin}/admin/login`, params)
            .then(function (response) {
                return response;
            });
    }

    // 登录
    register(data) {
        let params = this.postParams(data);
        return $http.post(`{admin}/admin/register`, params)
            .then(function (response) {
                return response;
            });
    }
    // 预登录
    preLogin(data){
        let params = this.postParams(data);
        return $http.post(`{admin}/preLogin`,params)
            .then(function (response) {
                return response.data;
            });
    }
    wxLogin(data){
        let params = this.postParams(data);
        return $http.post(`{admin}/wxLogin`,params)
            .then(function (response) {
                return response;
            });
    }

    makeWeCode(data){
        let params = this.postParams(data);
        return $http.post(`{admin}/makeWeCode`,params)
            .then(function (response) {
                return response.data;
            });
    }
}


export default (new LoginServices())
