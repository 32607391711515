<template>
  <el-dialog width="420px" v-model="showDialog" title="登录" :close-on-click-modal="false">
    <div class="qrCode-box" v-if="preLogin">
      <div class="qrCode-inner">
        <div class="qrCode" ref="qrCode" id="qrCode">
          <img :src="qrCodeUrlTime" v-if="qrCodeUrl">
        </div>
        <div class="qr-expire" @click="refreshQrCode" v-if="expire"><span>已过期，点击刷新</span></div>
      </div>
      <div class="tip">
        <p>1. 使用初次绑定的微信号</p>
        <p>2. 打开“微信”点击右上角扫码</p>
      </div>
    </div>
    <form action="" name="formSubmit" id="formSubmit" @submit.prevent="submit" v-else>
      <ul class="form-list">
        <li>
          <el-input v-model="model.username" placeholder="输入用户名"></el-input>
        </li>

        <li>
        <el-input type="password" v-model="model.password" placeholder="输入密码"></el-input>
        </li>

        <li class="img-code-item">
          <el-input type="text" v-model="model.imgCode" placeholder="图形验证码"></el-input>
          <span class="img-code" @click="changeImgCode"><img :src="imgCodeUrl"></span>
        </li>
        <li class="btns">
          <button type="submit" class="btn-img1" :disabled="loading" v-loading="loading">进入系统</button>
        </li>
      </ul>
    </form>
  </el-dialog>
</template>

<script>
import Services from "../../services/login.js";
import {_data, _methods} from "@/common/public";
import {DataValidation} from "@/common/dataValidation";

const dataValidation = new DataValidation();

export default {
  name: "LoginCom",
  components: {
  },
  data() {
    return {
      ..._data,
      showDialog: false,
      model: {
        username: '',
        password: '',
        imgCode: '',
        imgCodeToken: '',
        imgCodeId: 0
      },
      confirmPassword: '',
      imgCodeUrl: '',
      loading: false,
      rules: [
        {name: "username", checkType: "required", errorMsg: "用户名不能为空"},
        {name: "password", checkType: "required", errorMsg: "密码不能为空"},
        {name: "imgCode", checkType: "required", errorMsg: "图形验证码不能为空"},
      ],
      expire: true,
      preLogin: false,
      qrCodeLoading: false,
      qrCodeVersion: "release",//"release"，体验版为 "trial"
      qrCodeUrl: "",
      qrCodeUrlTime:"",
    };
  },
  methods: {
    ..._methods,
    show(){
      this.showDialog = true
      this.getImgCode()
    },
    close(){
      this.showDialog = false
    },
    submit() {
      this.loading = true;

      let v = dataValidation.validate(this.model, this.rules);
      if (!v.isOK) {
        this.msg(v.errorMsg,0);
        this.loading = false;
        return false;
      }

      /*Services.login(this.model).then(response => {
        this.loading = false;
        let res = response.data;
        console.log('login', response);
        if (res.Flag) {
          let data = res.Data
          localStorage.setItem('TOKEN', response.headers.authorization);
          localStorage.setItem('userInfo', JSON.stringify(data));
          localStorage.setItem('AuditStatus', data.audit_status);

          if (data.config) {
            localStorage.setItem('userConfig', data.config);
          }

          setTimeout(()=>{
            location.reload()
          },100)
        } else {
          this.getImgCode()
        }
      })*/
      Services.preLogin(this.model).then(res => {
        if (res.Flag) {
          this.preLogin = true

          setTimeout(() => {
            this.makeQrCode()
            this.monitoring()

            this.expire = false
          }, 100)
        }else{
          this.loading = false;
          this.getImgCode()
        }
      })
    },
    refreshQrCode() {
      this.submit()
    },
    //生成小程序码 -> 通过微信右上角的扫码功能进行扫码
    makeQrCode() {
      this.qrCodeLoading = true
      Services.makeWeCode({
        account: this.model.username || "test",
        area: this._CONFIG.location_code,
        type: "getwxacode",
        version: this.qrCodeVersion,
      }).then(res => {
        console.log("makeQrCodeWx", res)
        this.qrCodeUrl = `${this._CONFIG.host}${res.Data}`.replace("{district}", this._CONFIG.location_code)
        this.qrCodeUrlTime = this.qrCodeUrl+"?t="+new Date().getTime()
        /*let t = new Date().getTime()
        item.qrCode = `${this._CONFIG.host}${res.Img}?t=${t}`
        item.qrCode = item.qrCode.replace("{district}",this._CONFIG.location_code);*/
        this.qrCodeLoading = false
      });
    },
    monitoring() {
      let that = this
      let fn = function () {
        clearTimeout(that.timer)
        Services.wxLogin(that.model).then((response) => {
          let res = response.data;
          if (!res.Flag) {
            //失败了
            that.msg(res.Flag ? res.Msg : res.Msg + "，请刷新页面，重新登录", res.Flag);
            setTimeout(() => {
              location.reload()
            }, 6000)
          }

          //登录成功了
          if (res.Code === 200) {
            let data = res.Data
            localStorage.setItem('TOKEN', response.headers.authorization);
            localStorage.setItem('userInfo', JSON.stringify(data));
            localStorage.setItem('AuditStatus', data.audit_status);

            if (data.config) {
              localStorage.setItem('userConfig', data.config);
            }
            setTimeout(()=>{
              location.reload()
            },100)
          } else if (res.Code === 207) {
            that.expire = true
            clearTimeout(that.timer)
          } else {
            that.timer = setTimeout(() => {
              fn()
            }, 2000)
          }
        }).catch((err) => {
          console.log("login-err", err)
          that.loading = false;
        })
      }

      setTimeout(() => {
        fn()
      }, 1000)
    },
    getImgCode() {
      Services.getImgCode().then(res => {
        this.imgCodeUrl = `${res.Data}`; // data:image/png;base64,
        console.log(this.imgCodeUrl)
        this.model.imgCodeToken = res.Token;
        this.model.imgCodeId = res.Id;
      })
    },
    changeImgCode() {
      this.getImgCode()
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  height: 40px;
  border: none;
  background-color: rgba(255, 112, 68,1);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  &:hover{
    background-color: rgba(255, 112, 68,0.8);
  }
}

.img-code-item {
  display: flex;
}

.form-list {
  li{
    margin-bottom: 12px;
  }
}

.img-code {
  width: 100px;
  height: 40px;
  margin-left: 6px;
  cursor: pointer;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
  }
}

.form-help {
  font-size: 14px;
}
/*扫码 授权*/
.qrCode-box {
  text-align: center;

  .tip {
    margin-top: 10px;
    color: #fff;
    line-height: 1.6;
  }
}

.qrCode-inner {
  display: inline-block;
  background: #fff;
  border: #fff solid 1px;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
}

.qrCode {
  width: 100%;
  height: 100%;

  img {
    width: 240px;
    height: 240px;
  }
}

.qr-expire {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  cursor: pointer;
}
.el-button{
  width: auto;
}
</style>
