<template>
  <div class="platform-box">
    <PageSidebar></PageSidebar>
    <div class="main">
      <PageHeader></PageHeader>
      <div class="data-view"><router-view></router-view></div>
    </div>
  </div>

  <ifBrowsers></ifBrowsers>
</template>

<script>
import ifBrowsers from '../components/ifBrowsers/index.vue'
import PageHeader from "./components/PageHeader";
import PageSidebar from "./components/PageSidebar";

export default {
  name: "platformLayout",
  components: {
    ifBrowsers,
    PageHeader,
    PageSidebar
  },
  data() {
    return {
      active: 0,
    };
  },
  created() {
    console.log("平台",localStorage.getItem("AuditStatus"))
  },
  mounted() {
    document.getElementById("body").className = "body-height-100"
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.platform-box{
  width: 100%;
  display: flex;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
  }

  .data-view {
    flex: 1;
    box-sizing: border-box;
    background: #fff;
    padding: 20px 30px;
    margin-left: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
  }

}

</style>