import www from "@/layout/Www";
import index from "@/layout/Index";
import open from "@/layout/Open";

const openRoutes = [
    {
        path: '/',
        component: index,
        redirect: '/index',
        name: "index",
        meta: {title: '首页', classify: 'index', icon: 'index', group:'guest', isOpen: true, isShow: 0},
        children: [
            {
                path: '/index',
                component: () => import('@/views/index'),
                name: 'index',
                meta: {title: '首页', classify: 'index', icon: 'index',group:'guest', isOpen: true, isShow: 0}
            }
        ]
    },
    {
        path: '/login',
        component: open,
        redirect: '/login',
        name: "login",
        meta: {title: '欢迎登录', classify: 'login', icon: 'login', group:'guest',isOpen: true, isShow: 0},
        children: [
            {
                path: '/login',
                component: () => import('@/views/login'),
                name: 'Login',
                meta: {title: '欢迎登录', classify: 'login', icon: 'login', group:'guest',isOpen: true, isShow: 0}
            }
        ]
    },
    {
        path: '/register',
        component: open,
        redirect: '/register',
        name: "register",
        meta: {title: '欢迎注册', classify: 'register', icon: 'register', group:'guest',isOpen: true, isShow: 0},
        children: [
            {
                path: '/register',
                component: () => import('@/views/register'),
                name: 'register',
                meta: {title: '欢迎注册', classify: 'register', icon: 'register', group:'guest',isOpen: true, isShow: 0}
            }
        ]
    },
    {
        path: '/goods',
        component: www,
        redirect: '/goods/list/0',
        name: "goods",
        meta: {title: '商品列表', classify: 'goods', icon: 'goods', group:'guest',isOpen: true, isShow: 1},
        children: [
            {
                path: 'list/:cid',
                component: () => import('@/views/goods/list'),
                name: 'goodsList',
                meta: {title: '商品列表', classify: 'goods', icon: 'goods', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/goods/detail'),
                name: 'goodsDetail',
                meta: {title: '商品详情', classify: 'goods', icon: 'goods', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'special/:id',
                component: () => import('@/views/goods/special'),
                name: 'goodsSpecial',
                meta: {title: '商品专题', classify: 'goodsSpecial', icon: 'goodsSpecial', group:'guest',mainClass:"main-w100",isOpen: true, isShow: 1}
            },
            {
                path: 'cart',
                component: () => import('@/views/cart/index'),
                name: 'goodsCart',
                meta: {title: '我的进货单', classify: 'cart', icon: 'cart', group:'member',auditStatus:1,isOpen: false, isShow: 1}
            },
            {
                path: 'settlement',
                component: () => import('@/views/cart/settlement'),
                name: 'goodsSettlement',
                meta: {title: '提交订单', classify: 'settlement', icon: 'settlement', group:'member',auditStatus:1,isOpen: false, isShow: 1}
            },
            {
                path: 'search/:q',
                component: () => import('@/views/goods/search'),
                name: 'goodsSearchList',
                meta: {title: '商品搜索列表', classify: 'search', icon: 'search', group:'search',isOpen: true, isShow: 1}
            },
            {
                path: 'top/:cid',
                component: () => import('@/views/goods/top'),
                name: 'topList',
                meta: {title: '销量排行榜', classify: 'top', icon: 'top', group:'top',isOpen: true, isShow: 1}
            },
        ]
    },
    {
        path: '/package',
        component: www,
        redirect: '/package/list',
        name: "package",
        meta: {title: '礼包列表', classify: 'package', icon: 'package', group:'guest',isOpen: true, isShow: 1},
        children: [
            {
                path: 'list',
                component: () => import('@/views/package/list'),
                name: 'packageList',
                meta: {title: '礼包列表', classify: 'package', icon: 'package', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'settlement/:id',
                component: () => import('@/views/package/settlement'),
                name: 'packageSettlement',
                meta: { title: '提交订单', classify: 'package', icon: 'package', affix: true, isShow: 1 }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/package/detail'),
                name: 'packageDetail',
                meta: {title: '礼包详情', classify: 'package', icon: 'package', group:'guest',isOpen: true, isShow: 1}
            },
        ]
    },
    {
        path: '/bagShare',
        component: www,
        redirect: '/bagShare/index',
        name: "bagShare",
        meta: {title: '礼包方案推荐', classify: 'bagShare', icon: 'bagShare', group:'guest',isOpen: true, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/bagShare/index'),
                name: 'bagShareIndex',
                meta: {title: '礼包列表', classify: 'bagShare', icon: 'bagShare', group:'guest',isOpen: true, isShow: 1}
            },
        ]
    },
    {
        path: '/article',
        component: www,
        redirect: '/article/list/0',
        name: "article",
        meta: {title: '文章列表', classify: 'article', icon: 'article', group:'guest',isOpen: true, isShow: 1},
        children: [
            {
                path: 'list/:cid',
                component: () => import('@/views/article/list'),
                name: 'articleList',
                meta: {title: '文章列表', classify: 'article', icon: 'article', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'page/:ename',
                component: () => import('@/views/article/page'),
                name: 'articlePage',
                meta: {title: '文章单页', classify: 'article', icon: 'article', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/article/detail'),
                name: 'articleDetail',
                meta: {title: '文章详情', classify: 'article', icon: 'article', group:'guest',isOpen: true, isShow: 1}
            },
        ]
    },
    {
        path: '/notice',
        component: www,
        redirect: '/notice/list/0',
        name: "notice",
        meta: {title: '通知列表', classify: 'notice', icon: 'notice', group:'guest',isOpen: true, isShow: 1},
        children: [
            {
                path: 'list/:cid',
                component: () => import('@/views/notice/list'),
                name: 'noticeList',
                meta: {title: '通知列表', classify: 'notice', icon: 'notice', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/notice/detail'),
                name: 'noticeDetail',
                meta: {title: '通知详情', classify: 'notice', icon: 'notice', group:'guest',isOpen: true, isShow: 1}
            },
        ]
    },
    {
        path: '/collectMg',
        component: www,
        redirect: '/collectMg/index',
        name: "collectMaige",
        meta: {title: '采集', classify: 'collectMaige', icon: 'collectMaige', group:'guest',isOpen: true, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/collectMaige/index'),
                name: 'collectMgIndex',
                meta: {title: '采集商品', classify: 'collectMaige', icon: 'collectMaige', group:'guest',isOpen: true, isShow: 1}
            },
            {
                path: 'list/:cid',
                component: () => import('@/views/collectMaige/list'),
                name: 'collectMaigeList',
                meta: {title: '采集列表', classify: 'collectMaige', icon: 'collectMaige', group:'guest',isOpen: true, isShow: 1}
            },
        ]
    },
]

export {
    openRoutes
}